import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import check_icon from '../../img/check_icon.svg';
import './ThankYou.scss';

const ThankYouPage = ({ data }) => (
  <Layout color="1">
    <Helmet title={ `Thank You | ${data.site.siteMetadata.title}` }>
      <meta name="description" content="Thanks for reaching out!" />
    </Helmet>
    <div id="main-content" className="ThankYou">
      <img src={ check_icon } alt="text" className="ThankYou__image" />
      <h1 className="ThankYou__title">Thank you!</h1>
      <div className="ThankYou__text">You'll receive an email shortly with a link to your demo.</div>
    </div>
  </Layout>
)

export default ThankYouPage;

export const query = graphql`
  query ThankYouQuery {
    ...siteTitle
  }
`
